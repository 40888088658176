<template>
  <v-card>
    <v-card-title>Profile</v-card-title>
    <v-card-text>
      <p>Below is the profile information as per your identity provider.</p>
      <p>
        <strong>{{ this.$store.getters.userForPortalAccess.name }}</strong> <br />{{ this.$store.getters.userForPortalAccess.email }}
      </p>
      <v-btn color="primary" dark @click.prevent="logout">{{
        $t("logout")
      }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Profile",
  methods: {
    logout() {
      this.$auth.logout({
        returnTo:
          window.location.protocol + "//" + window.location.host + "/login"
      });
    }
  }
};
</script>
