// auth.js
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_DELEGATED_USER = "UPDATE_DELEGATED_USER";

// feedback.js
export const RESPONSE_MSG = "RESPONSE_MSG";
export const CLEAR_MSG = "CLEAR_MSG";
