<template>
  <v-layout row>
    <v-flex xs12 sm8 offset-sm2 lg4 offset-lg4>
      <v-card>
        <v-card-title>
          Log into MyDocPort
        </v-card-title>
        <v-card-text>
          Please log in to use MyDocPort
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Login",
  data: () => ({}),
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>
