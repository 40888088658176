<template>
  <div>
    <v-dialog v-model="menu" :close-on-content-click="false" offset-y max-width="50%">
      <v-card>
        <v-card-text>
          <ApolloQuery 
            :query="require('@/graphql/news/allNews.gql')"
            :variables="news_query_vars">
            <template #default="{ result: { data, loading, error } }">
              <div v-if="loading">Loading...</div>
              <div v-else-if="error">{{ error.message }}</div>
              <div v-else>
                <ul v-if="data && data.allNews.length > 0" style="list-style-type:none; padding:0;">
                  <li v-for="news in data.allNews" :key="news.id">
                    <div class="news-type">
                      <p v-html="news.news_type" style="font-weight:bold;font-size:18px;padding-top:10px;text-transform:uppercase;"></p>
                    </div>
                    <div class="news-content">
                      <p v-html="addTargetBlank(news.news_content)" style="padding-left:30px;padding-top:10px;"></p>
                    </div>
                  </li>
                </ul>
                <div v-else>No messages</div>
              </div>
            </template>
          </ApolloQuery>
        </v-card-text>
        <v-card-actions>
          <!-- Botón para cerrar el menú -->
          <v-btn color="primary" text @click="menu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  name: "NewsCard",
  data() {
    return {
      // Modelo para controlar la apertura y cierre del menú
      menu: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "authUser"
    }),
    news_query_vars: function() {
      if (this.$store.getters.userForPortalAccess.client) {
        return {client: this.$store.getters.userForPortalAccess.client.id};
      } else {
        return {};
      }
    },
    query_vars() {
      return { client_id: this.$store.getters.userForPortalAccess.client.id };
    }
  },
  methods: {
    addTargetBlank(content) {
      // crea un elemento temporal para contener el contenido de la noticia
      const tempEl = document.createElement('div')
      tempEl.innerHTML = content
      // encuentra todos los elementos <a> en el contenido de la noticia y agrega el atributo target="_blank"
      tempEl.querySelectorAll('a').forEach(link => link.setAttribute('target', '_blank'))
      // devuelve el contenido de la noticia con el atributo target="_blank" agregado a todos los enlaces
      return tempEl.innerHTML
    },
    goBack() {
      this.$emit("go-back");
    },
  }
};
</script>