<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Logged Out</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <p>
              You are logged out of the MyDocPort application. Please login to
              continue.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Logout",
  components: {},
  methods: {
    // Log the user in
    login() {}
  }
};
</script>

<style scoped></style>
