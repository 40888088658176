import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import vuetify from "./plugins/vuetify";
import AppLayout from "@/layouts/AppLayout.vue";
import UserAppLayout from "@/layouts/UserAppLayout";
import SimpleLayout from "@/layouts/SimpleLayout.vue";
//import VueAuth from "@/plugins/vue-auth";
import { Auth0Plugin } from "./auth";
import { domain, clientId, audience } from "../auth_config.json";
import i18n from "./i18n";
import { createProvider } from "./vue-apollo";
import Clipboard from "v-clipboard";
Vue.component("app-layout", AppLayout);
Vue.component("user-app-layout", UserAppLayout);
Vue.component("simple-layout", SimpleLayout);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  Store: store,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
// Vue.use(VueAuth);
Vue.use(vuetify, {
  theme: {
    primary: "#03a9f4",
    secondary: "#2196f3",
    accent: "#ff5722",
    error: "#f44336",
    warning: "#ffc107",
    info: "#607d8b",
    success: "#4caf50"
  }
});
Vue.use(Clipboard);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
