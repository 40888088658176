<template>
  <v-alert dense type="info" icon="mdi-bell" dismissible v-model="alert">
    <strong>{{ message.data.title }}</strong
    ><br />{{ theDate }}<br />
    From: {{ message.data.from }}<br />
    {{ message.data.message }}<br />
    <span class="caption">{{ persistentNotice }}</span>
  </v-alert>
</template>

<script>
import moment from "moment";

export default {
  name: "NotificationMessage",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    alert: true
  }),
  methods: {
    close() {
      this.$store.dispatch("clearMessage");
    }
  },
  computed: {
    theDate: function() {
      return moment(this.message.created_at, "YYYY-MM-DD").format("MM/DD/YYYY");
    },
    persistentNotice: function() {
      if (this.message.data.persistent === "y") {
        return (
          "** You can dismiss this message but it cannot be deleted until " +
          this.persistentDate
        );
      } else {
        return "";
      }
    },
    persistentDate: function() {
      return moment(this.message.data.persistent_until, "YYYY-MM-DD").format(
        "MM/DD/YYYY"
      );
    }
  },
  watch: {
    alert: function(val) {
      if (!val) {
        // if the persistent date is greater than today, don't send the markRead ...
        let d = new Date(this.message.data.persistent_until);
        let today = new Date();
        if (
          d.getTime() < today.getTime() ||
          this.message.data.persistent === "n"
        ) {
          this.$apollo
            .mutate({
              // Query
              mutation: require("@/graphql/notification/markNotificationsRead.gql"),
              // Parameters
              variables: { ids: [this.message.id] }
            })
            // eslint-disable-next-line no-unused-vars
            .then(data => {
              //remove from store....
              this.$store.dispatch("fetchUser", this.$store.getters.authToken);
            })
            .catch(error => {
              // Error
              console.error(error);
            });
        }
      }
    }
  }
};
</script>
