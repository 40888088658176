import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import routes from "./routes";
//import store from "@/store";
import { authGuard } from "@/auth";
import { adminGuard } from "@/auth";

Vue.use(Router);
Vue.use(Meta);

const router = make(routes({ adminUserGuard, userGuard }));

export default router;

/**
 * Create a new router instance.
 *
 * @param  {Array} routes
 * @return {Router}
 */
function make(routes) {
  const router = new Router({
    routes,
    scrollBehavior,
    mode: "history"
  });
  return router;
}
/* eslint-disable no-unused-vars */
function adminUserGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    /*
    if (!store.getters.adminCheck) {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else if (to.matched.some(record => record.meta.permission)) {
      if (checkPermissions(to.meta.permission)) {
        next();
      } else {
        store.dispatch("responseMessage", {
          type: "error",
          text: "You do not have permissions for this route"
        });
        // next({
        //   name: 'home',
        // })
        next();
      }
    } else {
      next();
    }
   */
    adminGuard(to, from, next);
  });
}
/**
 * Redirect home if authenticated.
 *
 * @param  {Array} routes
 * @return {Array}
 */

function userGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    /*
    if (!store.getters.authCheck) {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
     */
    authGuard(to, from, next);
  });
}
/* eslint-enable no-unused-vars */
/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */

function beforeEnter(routes, beforeEnter) {
  return routes.map(route => {
    return { ...route, beforeEnter };
  });
}
/**
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  const position = {};

  if (to.hash) {
    position.selector = to.hash;
  }

  if (to.matched.some(m => m.meta.scrollToTop)) {
    position.x = 0;
    position.y = 0;
  }

  return position;
}

// eslint-disable-next-line no-unused-vars
function checkPermissions(req) {
  return true;
  /*
  const permissions = store.getters.authPermissions
  if(permissions.includes('admin-super') || req.includes('none') || req === 'none')
  {
    return true
  }
  if(req instanceof Array)
  {
    //let found = req.some(r=> this.permissions.includes(r))
    return req.filter((p) => permissions.includes(p)).length > 0;
  }else{
    return permissions.includes(req)
  }

   */
}
