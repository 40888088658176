import { mapGetters } from "vuex";

export const authGate = {
  computed: {
    ...mapGetters({
      authPermissions: "authPermissions",
      authUser: "authUser"
    })
  },
  methods: {
    checkUser(type) {
      if (type === "" || type === "all") {
        return true;
      } else if (type === this.$store.getters.userForPortalAccess.user_type) {
        return true;
      } else {
        return false;
      }
    },

    // eslint-disable-next-line no-unused-vars
    checkPermissions(req) {
      // console.log(req);
      return true;
      /*
      if (
        this.authPermissions.includes("admin-super") ||
        req.includes("none") ||
        req === "none"
      ) {
        return true;
      }
      if (req instanceof Array) {
        return req.filter(p => this.authPermissions.includes(p)).length > 0;
      } else {
        return this.authPermissions.includes(req);
      }
      */
    }
  }
};
