import { getInstance } from "./authWrapper";

export const adminGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = async function() {
    if (authService.isAuthenticated) {
      await authService.getMe();
      if (authService.getUserForPortalAccess().user_type === "admin") {
        return next();
      } else {
        if (authService.getUserForPortalAccess().client) {
          if (authService.getUserForPortalAccess().client.id > 0) {
            return next({ name: "splash" });
          }
        } else {
          return next({ name: "unauthorized" });
        }
      }
    }
    if (!to.query.error) {
      await authService.loginWithRedirect({
        appState: { targetUrl: to.fullPath }
      });
    } else {
      return next({ name: "verify" });
    }
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
