import axios from "axios";
import Cookies from "js-cookie";
import * as types from "../mutation-types";
// import _ from "lodash";
// state
export const state = {
  user: {},
  access_token: Cookies.get("access_token"),
  refresh_token: Cookies.get("refresh_token"),
  permissions: []
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, { token, refresh }) {
    state.access_token = token;
    state.refresh_token = refresh;
    Cookies.set("token", token, { expires: 365 });
    Cookies.set("refresh_token", refresh, { expires: 365 });
    /*
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let data = JSON.parse(window.atob(base64));
    state.permissions = _.map(data.permissions, function(x) {
      return x.name;
    });
     */
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.access_token = null;
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.access_token = null;
    state.refresh_token = null;
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
  },

  [types.UPDATE_USER](state, user) {
    state.user = user;
  },

  [types.UPDATE_DELEGATED_USER](state, delegatedUser) {
    state.delegatedUser = delegatedUser;
  }
};

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit }, token) {
    try {
      const { data } = await axios({
        method: "POST",
        url: process.env.VUE_APP_GRAPHQL_HTTP,
        headers: {
          Authorization: "Bearer " + token
        },
        data: {
          query: `
                            {
                                me {
                                    name
                                    id
                                    email
                                    sub
                                    user_type
                                    unreadNotifications {
                                      id
                                      created_at
                                      read_at
                                      data{
                                      persistent
                                      persistent_until
                                      title
                                      message
                                      from
                                      }
                                    }
                                    client {
                                       name
                                       id
                                       feedback_iframe_target
                                       }
                                }
                            }
                        `
        }
      });
      commit(types.FETCH_USER_SUCCESS, { user: data.data.me });
    } catch (e) {
      commit(types.FETCH_USER_FAILURE);
    }
  },

  async updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async updateDelegatedUser({ commit }, payload) {
    commit(types.UPDATE_DELEGATED_USER, payload);
  },

  // eslint-disable-next-line no-unused-vars
  async logout({ commit }) {
    commit(types.LOGOUT);
  }
};

// getters
export const getters = {
  authUser: state => state.user,
  delegatedUser: state => state.delegatedUser,
  userForPortalAccess: state => state.delegatedUser ? state.delegatedUser : state.user,
  adminCheck: state => state.user !== null && state.user !== "",
  authToken: state => state.access_token,
  refreshToken: state => state.refresh_token,
  authPermissions: state => state.permissions,
  authCheck: state => state.user !== null && state.user !== ""
};
