import Vue from "vue";
import axios from "axios";
import createAuth0Client from "@auth0/auth0-spa-js";
import store from "../store";
import { createProvider } from "@/vue-apollo";
// eslint-disable-next-line no-unused-vars
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;
export const getInstance = () => instance;
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  Apollo = createProvider().defaultClient,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        me: null,
        delegatedUser: store && store.getters && store.getters.delegatedUser
      };
    },
    methods: {
      async switchToSuperAdmin() {
        const authService = getInstance();
        await authService.updateDelegatedUser(null);
        await axios
          .get(process.env.VUE_APP_API_ENDPOINT + "/loginById/" + this.me.id)
          .then(response => {
            console.log(`loginById response ${response}`);
          })
          .catch(console.error);
        setTimeout(async () => {
          await this.loginWithRedirect();
        }, 100);
      },
      updateDelegatedUser(delegatedUser) {
        options.Store.dispatch("updateDelegatedUser", delegatedUser);
        this.delegatedUser = delegatedUser;
      },
      getUserForPortalAccess() {
        return this.delegatedUser ? this.delegatedUser : this.me;
      },
      async getMe() {
        if (!this.me) {
          await Apollo.query({
            // Query
            query: require("@/graphql/user/me.gql")
          }).then(data => {
            options.Store.dispatch("updateUser", data.data.me);
            this.me = data.data.me;
          });
        } else {
          await this.me;
        }
      },
      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.me = await this.getMe();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      logout(o) {
        return this.auth0Client.logout(o);
      }
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri
      });

      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.loading = false;
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
