<template>
  <v-app-bar fixed app dark color="primary">
    <v-toolbar-title>
      <router-link :to="{ name: 'dashboard' }" class="white--text">
        MyDocPort
      </router-link>
    </v-toolbar-title>
    <v-spacer />

    <!-- Authenticated -->
    <template v-if="!$auth.isAuthenticated && !$auth.loading">
      <v-btn class="ma-2" text small @click.prevent="login">{{
        $t("login")
      }}</v-btn>
    </template>

    <!-- Guest -->
    <template v-else>
      <v-btn class="ma-2" text small :to="{ name: 'userprofile' }">{{
        this.$store.getters.userForPortalAccess.name
      }}</v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ma-2" v-if="hasDelegatedUser" @click.prevent="switchToSuperAdmin" style="color: #ffa500;">
          mdi-account-switch
          </v-icon>
        </template>
        <span>Switch back to Administrator</span>
      </v-tooltip>
      <v-btn class="ma-2" text small @click.prevent="logout">{{
        $t("logout")
      }}</v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { getInstance } from "../../auth/authWrapper";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    appName: "MyDocPort",
    busy: false
  }),
  computed: {
    ...mapGetters({
      delegatedUser: "delegatedUser"
    }),
    hasDelegatedUser() {
      return this.delegatedUser != null;
    },
    authenticated() {
      if (this.$store.getters.authCheck) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async switchToSuperAdmin() {
      const authService = getInstance();
      await authService.switchToSuperAdmin();
    },
    // Log the user in
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo:
          window.location.protocol + "//" + window.location.host + "/login"
      });
    }
  }
};
</script>
